import { watchViewport } from 'tornis';

import { CURSOR_ROTATE_SPEED, CURSOR_ROTATE_RADIUS } from './constants';
import { computeEffectivePoint } from './utils';

const Effect = ({
  segments,
  width: logoWidth,
  height: logoHeight,
  color = '#ffffff',
  scale = { x: 1, y: 1 }
}) => {
  let { innerWidth: width, innerHeight: height } = window;

  let canvasOffsetX = 0;
  let canvasOffsetY = 0;
  let divider = 1;

  const canvas = document.getElementById('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  const mouse = { x: 0, y: 0 };
  const cursor = { x: 0, y: 0 };

  const offsetPoint = point => {
    const offsetPt = { ...point };

    offsetPt.x *= scale.x;
    offsetPt.y *= scale.y;
    offsetPt.x *= divider;
    offsetPt.y *= divider;
    offsetPt.x += canvasOffsetX;
    offsetPt.y += canvasOffsetY;

    return offsetPt;
  };

  const offsetCurvePoint = point => {
    const offsetPt = offsetPoint(point);

    offsetPt.cpx *= scale.x;
    offsetPt.cpy *= scale.y;
    offsetPt.cpx *= divider;
    offsetPt.cpy *= divider;
    offsetPt.cpx += canvasOffsetX;
    offsetPt.cpy += canvasOffsetY;

    return offsetPt;
  };

  const drawLine = line => {
    line.forEach(point => {
      const { x: fx, y: fy } = computeEffectivePoint(
        offsetPoint(point),
        cursor
      );

      ctx.lineTo(fx, fy);
    });
  };

  const drawCurve = curve => {
    const [p1, p2] = curve;

    const offsetP1 = offsetCurvePoint(p1);
    const offsetP2 = offsetCurvePoint(p2);

    const { cpx: fcpx1, cpy: fcpy1 } = computeEffectivePoint(offsetP1, cursor);

    const { x: fx2, y: fy2, cpx: fcpx2, cpy: fcpy2 } = computeEffectivePoint(
      offsetP2,
      cursor
    );

    ctx.bezierCurveTo(fcpx1, fcpy1, fcpx2, fcpy2, fx2, fy2);
  };

  const drawSegments = () => {
    for (let layer = 0; layer < segments[0].length; layer += 1) {
      ctx.beginPath();

      const start = computeEffectivePoint(
        offsetPoint(segments[0][layer][0]),
        cursor
      );

      ctx.moveTo(start.x, start.y);

      segments.forEach(subSegments => {
        const segment = subSegments[layer];

        if (segment.type === 'CURVE') {
          drawCurve(segment);
        } else {
          drawLine(segment);
        }
      });

      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    }
  };

  const rotateCursor = time => {
    const sin = Math.sin((time / 1000) * CURSOR_ROTATE_SPEED);
    const cos = Math.cos((time / 1000) * CURSOR_ROTATE_SPEED);

    cursor.x = mouse.x + CURSOR_ROTATE_RADIUS * cos;
    cursor.y = mouse.y + CURSOR_ROTATE_RADIUS * sin;
  };

  const animate = time => {
    requestAnimationFrame(animate);

    rotateCursor(time);

    ctx.clearRect(0, 0, width, height);
    drawSegments();
  };

  const trackViewportSize = ({ size }) => {
    if (size.changed) {
      const { x, y } = size;

      width = x;
      height = y;

      canvas.width = width;
      canvas.heigh = height;

      divider = Math.min(width / (logoWidth * scale.x + 40), 1);

      canvasOffsetX = (width - logoWidth * scale.x * divider) / 2;
      canvasOffsetY = (height - logoHeight * scale.y * divider) / 2;
    }
  };

  const start = () => {
    document.addEventListener('mousemove', event => {
      let offsetX = 0;
      let offsetY = 0;

      if (event.changedTouches) {
        offsetX = event.touches[0].pageX;
        offsetY = event.touches[0].pageY;
      } else {
        /* eslint-disable prefer-destructuring */
        offsetX = event.offsetX;
        offsetY = event.offsetY;
        /* eslint-enable prefer-destructuring */
      }

      console.log(offsetX, offsetY);

      mouse.x = offsetX;
      mouse.y = offsetY;
    });

    watchViewport(trackViewportSize);

    ctx.translate(0.5, 0.5);
    animate();
  };

  return { start };
};

export default Effect;
