import Effect from './src/Effect';

import Line from './src/Line';
import BezierCurve from './src/BezierCurve';

(() => {
  const init = () => {
    const segments = [
      BezierCurve(50, 100, 69.5, 100, 85, 65, 85, 84),
      Line(85, 65, 85, 0),
      Line(85, 0, 15, 0),
      Line(15, 0, 15, 65),
      BezierCurve(15, 65, 15, 84, 50, 100, 30.5, 100)
    ];

    const effect = Effect({
      segments,
      width: 100,
      height: 100,
      color: 'rgba(0, 0, 0, 0.3)',
      scale: { x: 3, y: -3 }
    });

    effect.start();
  };

  document.addEventListener('DOMContentLoaded', init);
})();
